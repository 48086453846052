<template>
    <modal
        class="edit-profile"
        :adaptive="true"
        :scrollable="true"
        name="edit-profile"
        width="585"
        height="auto"
    >
        <div class="close-modal" @click="$modal.hide('edit-profile')">
            <img src="@assets/img/icons/x.svg" alt="X" width="25px">
        </div>
        <div class="row no-gutters">
            <div class="col">
                <h1>Edit profile</h1>
                <profile-form ref="profile_form" @saved-user-profile="savedUserProfile" />
                <div class="submit-profile">
                    <button class="btn btn-primary" @click="$refs.profile_form.submitProfile()">
                        Save changes
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: "EditProfileModal",
    components: {
        ProfileForm: () => import(/* webpackChunkName: "profile-form" */ "@c/organisms/profile-form")
    },
    methods: {
        savedUserProfile(data) {
            this.$emit("saved-user-profile", data);
            if (this.$route.params.displayName != data.displayname) {
                this.$router.push({ name: "web-app-profile", params: { displayName: `${data.displayname}` } });
            }

            this.$modal.hide("edit-profile");
        }
    }
}
</script>

<style lang="scss" scoped>
.edit-profile {
    .close-modal {
        position: absolute;
        right: 30px;
        top: 30px;
        cursor: pointer;
        z-index: 2;

        img {
            width: 15px;
        }

        @media(max-width: $sm) {
            right: 15px;
            top: 15px;
        }
    }

    /deep/ .vm--modal {
        background-color: #0F0F10;
        border-radius: 30px;
        box-shadow: none;
        padding-top: 35px;

        h1 {
            text-align: center;
            margin-bottom: 15px;
        }

        .profile-form {
            padding-bottom: 0;
            z-index: 2;
            position: relative;

            .form-content {
                padding: 25px 50px;
                padding-top: 0;

                @media(max-width: $sm) {
                    padding: 25px 20px;
                }

                .profile-picture {
                    span {
                        display: none;
                    }
                }
            }
        }

        .submit-profile {
            display: flex;
            justify-content: flex-end;
            padding: 25px 50px;
            padding-top: 0;
        }
    }
}
</style>
